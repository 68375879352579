/*
 * Copyright (C) COMPOFFICE-R Kft, RentIT - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 *
 */
var Layout = (function () {
    'use strict';
    /* ----- Declare Valiables ---------------------------------------------------------------------------------------- */
    var $templateBackdrop;
    var allslides = 0;
    var actslide = 0;
    var slideTimer;

    /* ----- Init Backdrop on Ready ----------------------------------------------------------------------------------- */
    function Init() {

        buildFooter();
        $(window).on('resize', function () {
            buildFooter();
        });
        document.title = AppData.modulsettings.browsertitle[localState.lang];

        $templateBackdrop = $('template#backdrop').remove().contents();
        setFavicon();
        delete Layout.Init;
    }

    function buildFooter() {

        //clearTimeout(slideTimer);
        cancelAnimationFrame(slideTimer);

        var regexp = /\[img:([0-9a-zA-Z]{8})\]/g;
        var statusline_images;
        var matches = [];
        while (statusline_images = regexp.exec(AppData.settings.statusline)) {
            matches.push(statusline_images);
        }

        var sline = AppData.settings.statusline;
        var images_loaded = 0;
        allslides = 0;

        for (var i in matches) {
            var image = AppData.medias.find(function (item) {
                return item.id == matches[i][1]
            });
            if (image && image.files[localState.lang]) {
                allslides++;

                let file = image.files[localState.lang].file;

                var img = new Image();
                img.src = file; //"upload/media/" + image.filename;
                img.onload = function() {
                    images_loaded++;
                    if (images_loaded == matches.length) {
                        setFooterWidth();
                    }
                }

                sline = sline.replace(matches[i][0], '<img class="footer_item" src="' + file + '">');
            } else {
                sline = sline.replace(matches[i][0], '');
            }
        }

        var regexp_text = /\[text:([^\]]*)\]/g;
        var statusline_texts;
        var matches_text = [];
        while (statusline_texts = regexp_text.exec(sline)) {
            matches_text.push(statusline_texts);
        }

        for (var i in matches_text) {
            allslides++;
            sline = sline.replace(matches_text[i][0], '<div class="footer_item"><span>' + matches_text[i][1] + '</span></div>');
        }

        var version = '<div class="sline">' + sline + '</div>';

        $('#software').css({
            position: "fixed",
            top: "-1000px"
        }).appendTo($("body"));

        /* Set Header Version String */
        $('#software .software_inner').html(version);

        if (matches.length == 0) {
            setFooterWidth();
        }
    }

    function setFooterWidth() {
        var all_width = 0;
        $(".software_inner .sline .footer_item").each(function () {
            all_width += Math.ceil($(this).outerWidth());
        });

        $(".software_inner .sline").css("width", all_width);

        $("#software").css({
            position: "",
            top: ""
        }).appendTo($("#landing"));

        actslide = 0;

        setTimeout(function (){
            if (all_width > $("#software .software_inner").outerWidth()) {
                slideFooter();
            }
        }, 0);
    }

    function slideFooter() {
        slideTimer = requestAnimationFrame(moveFooter);
    }

    function moveFooter() {
        var first = $("#software .software_inner .sline .footer_item").first();

        var currTrans = $("#software .software_inner .sline").css('transform').split(/[()]/)[1];
        var posx = currTrans.split(',')[4];

        var nextpos = posx - 0.5;

        $("#software .software_inner .sline").css({
            transform: 'translateX('+nextpos+'px)'
        });

        slideTimer = requestAnimationFrame(moveFooter);

        if (-first.outerWidth() > nextpos) {
            first.appendTo($("#software .software_inner .sline"));
            $("#software .software_inner .sline").css({
                transform: 'translateX(0)'
            });
        }

    }

    /* ----- Show Layout ---------------------------------------------------------------------------------------------- */
    function show(id) {
        var
            $el = $('#' + id).not('#landing'),
            $isSystem = $el.hasClass('system'),
            $backdrop = $templateBackdrop.clone();
        if ($el.length && !$el.is(':visible')) {
            /* localState declared in /src/shared/common.js */
            localState.layout = localState.layout.filter(function (el) {
                return el !== id;
            });
            localState.layout.push(id);
            $('.bubbles1, .bubbles2').hide();
            $el
                //.off( 'animationend animationcancel' )
                .show()
                .removeClass('fadeOutRight fadeOut')
                .addClass($isSystem ? 'animated faster fadeIn' : 'animated faster fadeInRight')
                .appendTo('body');
            if (!$isSystem) {
                $backdrop
                    .show()
                    .addClass('animated faster fadeIn')
                    .insertBefore($el);
            }
        }
        $el = $isSystem = $backdrop = null;
    }

    /* ----- Hide Layout ---------------------------------------------------------------------------------------------- */
    function hide(id) {
        var
            $el = $('#' + id).not('#landing'),
            $isSystem = $el.hasClass('system'),
            $backdrop = $el.prev('main.backdrop'),
            $scrolltop = $el.find('.scroll-top-wrapper');
        if ($el.length && $el.is(':visible')) {
            /* localState declared in /src/shared/common.js */
            localState.layout = localState.layout.filter(function (el) {
                return el !== id;
            });
            $scrolltop.removeClass('fadeInUp').addClass('hidden');
            $el
                .on('animationend animationcancel', function (e) {
                    var target = e.target || e.originalEvent.target;
                    if (target === this) {
                        $el
                            .off('animationend animationcancel')
                            .removeClass('animated faster fadeOut fadeOutRight').hide();
                        $backdrop.remove();
                        if ($('main:visible').length === 1) {
                            $('.bubbles1, .bubbles2').show();
                        }
                        $el = $isSystem = $backdrop = $scrolltop = null;
                    }
                    target = null;
                })
                .removeClass('faster fadeInRight fadeIn')
                .addClass($isSystem ? 'animated faster fadeOut' : 'animated faster fadeOutRight');
            if (!$isSystem) {
                $backdrop
                    .removeClass('fadeIn')
                    .addClass('animated faster fadeOut');
            }
        }
    }

    /* ----- Swap Layout ---------------------------------------------------------------------------------------------- */
    function swap(id) {
        var $el = $('#' + id);
        $el.siblings('main:visible').not('.backdrop').each(_eachSwapHide);
        show(id);
        $el = null;
    }

    /* ----- Swap Layout ---------------------------------------------------------------------------------------------- */
    function hideAll() {
        $('main:visible').not('.backdrop').each(_eachSwapHide);
    }

    /* Each Function */
    function _eachSwapHide(i, el) {
        hide(el.id);
    }

    /* ----- Event: Fire Open Layout ---------------------------------------------------------------------------------- */
    function openerOnClick(e) {
        e.preventDefault();
        var selector = $(this).data('target');
        var id = $(this).data('id');

        /*var title = AppData.navigation.filter(function(e) {
            return e.id === id;
        });*/

        localState.layoutActiveMenu = id;

        if ($(this).data('targetid')) {
            /*if(title.length !== 0) {
                $('main#' + selector + '-details header').find('h3 .data-icon').removeAttr('class').addClass("data-icon " + $(this).data('icon'));
            }*/
            selector = selector.charAt(0).toUpperCase() + selector.slice(1);
            window[selector].showDetails($(this).data('targetid'));

            if (typeof window[selector].showList == "function") {
                window[selector].showList($(this).data('targetid'));
            }

        } else {
            selector = selector.toLowerCase();
            /*if(title.length !== 0) {
                var header = $('main#' + selector + ' header');
                header.find('h3 .data-icon').removeAttr('class').addClass("data-icon " + $(this).data('icon'));
                header.find('h3 .data-label').html(title[0].name[localState.lang]);
            }*/

            var selector_class = selector.charAt(0).toUpperCase() + selector.slice(1);
            if (window[selector_class] != undefined && typeof window[selector_class].showList == "function") {
                window[selector_class].showList("");
            }

            Layout.show(selector);
            selector = null;
        }
    }

    /* ----- Bind Events ---------------------------------------------------------------------------------------------- */
    $(window).on('resize orientationchange', function () {
        var $bubbles = $('.bubbles1, .bubbles2');
        $('#resolution').text(screen.width + 'x' + screen.height);
        if ($bubbles.is(':visible')) {
            $($bubbles).hide();
            requestAnimationFrame(function () {
                $bubbles.show();
                $bubbles = null;
            });
        }
    });

    $(document).on('mouseup touchend', '.open-main', openerOnClick);

    /* ----- Public API ----------------------------------------------------------------------------------------------- */
    return {
        Init: Init,
        show: show,
        hide: hide,
        swap: swap,
        hideAll: hideAll
    };
})();
