/*
 * Copyright (C) COMPOFFICE-R Kft, RentIT - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 *
 */
var Modal = (function () {
    'use strict';
    /* ----- Declare Variables ---------------------------------------------------------------------------------------- */
    var
        $layoutMain,
        fnCallback;

    /* ----- Init ----------------------------------------------------------------------------------------------------- */
    function Init() {
        $layoutMain = $('main#modal');
        $layoutMain.hide();
        $(document).on('click', 'main#modal .btn.accept, main#modal .btn.close', function () {
            $layoutMain.hide();
            if (typeof fnCallback === 'function') {
                fnCallback.call();
                fnCallback = null;
            }
        });
        $(document).on('click', 'main#modal .btn.dismiss', function () {
            $layoutMain.hide();
        });
        delete Modal.Init;
    }

    /* ----- Alert ---------------------------------------------------------------------------------------------------- */
    function alert(text, callback) {
        fnCallback = callback;
        $layoutMain.find('.title').text(Language.getItem('modal-alert'));
        $layoutMain.find('.text').text(text);
        $layoutMain.find('.btn.accept').hide();
        $layoutMain.find('.btn.dismiss').hide();
        $layoutMain.find('.btn.close').show();
        $layoutMain.show();
    }

    /* ----- Confirm -------------------------------------------------------------------------------------------------- */
    function confirm(text, callback) {
        fnCallback = callback;
        $layoutMain.find('.title').text(Language.getItem('modal-confirm'));
        $layoutMain.find('.text').text(text);
        $layoutMain.find('.btn.accept').show();
        $layoutMain.find('.btn.dismiss').show();
        $layoutMain.find('.btn.close').hide();
        $layoutMain.show();
    }

    /* ----- Public API ----------------------------------------------------------------------------------------------- */
    return {
        Init: Init,
        alert: alert,
        confirm: confirm
    }
})();