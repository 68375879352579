/**
 * Copyright (C) COMPOFFICE-R Kft, RentIT - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 *
 */
'use strict';
/* ----- Global Variables ------------------------------------------------------------------------------------------- */
/* localState declared in /src/shared/common.js */
localState.layout = localState.layout || [];
//localState.lang = localState.lang || 'hu';
/* ----- Hold Ready ------------------------------------------------------------------------------------------------- */
//$.holdReady(true);

checkReady();

/* Load Client Fragment Content */
$.get(url_rewrite + 'modules/mod_registration.html', _ajaxReadyModClient);

function _ajaxReadyModClient(data) {
    $('.bodycontent').html(data);
    $('main').hide();
    is_ready.html = true;
    //$.holdReady(false);
}

function checkReady() {
    if (is_ready.config && is_ready.html) {
        clearTimeout(waitForReady);
        //$.holdReady(false);
        sharedReady();
        registrationReady();
    } else {
        waitForReady = setTimeout(function (){
            checkReady();
        }, 100);
    }
}

/* ----- Process on Document Ready ---------------------------------------------------------------------------------- */
function registrationReady() {
    Layout.Init();
    Settings.Init();
    Language.Init();
    Modal.Init();

    $.post('api/', {
        do: 'getTranslationsWithoutLogin'
    }, function (data) {
        var loginlanguagesFragment = $(document.createDocumentFragment());
        $.each(data, function(i,e) {
            $("<option/>").val(e.translation_id).text(e.translation_name).attr('selected', localState.lang == e.translation_id).appendTo(loginlanguagesFragment);
        });
        $("#landing").find(".changeLang").html(loginlanguagesFragment);

        var actlang = data.find(function (item) {
            return item.translation_id == localState.lang;
        });

        if (actlang == undefined) {
            Language.Init(AppData.settings.lang);
        }

        data = null;
    });

    Modulsettings.Init();

    $('select.changeLang')
        .val(localState.lang)
        .on('change', function () {
            Language.Init(this.value);
            createForm();
        });

    $('#formReg').on('submit', createUser);

    $("#formReg .login").on("click", function(){
        location.replace("login.html");
    });

    createForm();

    function createUser(e) {
        e.preventDefault();

        $('#formReg').addClass("disabled");

        var
            formData = $(this).serializeObject();
        $.post('api/', {
            do: 'createUser',
            data: formData
        }, function (res) {
            if (res.error != undefined) {
                if (res.msg_type == "regexp") {
                    Modal.alert(res.message[localState.lang].error_msg);
                } else {
                    Modal.alert(Language.getItem(res.message));
                }
                $('#formReg').removeClass("disabled");
            } else {
                localStorage.setItem('last_logged_in', res.qrcode);
                if (res.id) {
                    Modal.alert(Language.getItem('reg-success'), function () {
                        if (AppData.registration.force_login == 1) {
                            location.replace(res.hash);
                        } else {
                            location.replace("login.html");
                        }
                    });
                }
            }
        });
    }
}

function getRegistrationData() {
    $.ajax({
        method: 'post',
        url: 'api/',
        data: {
            do: 'getRegistrationData'
        },
        success: function (resp) {
            AppData.registration = resp;
            createForm();
        }
    });
}

function createForm() {
    if (AppData.registration.reg_allowed != 1) {
        location.replace("login.html");
    }

    switch (AppData.registration.login_type) {
        case "non-sec-plus":
        case "non-sec":
        case "basic-easy":
        case "basic":
        default:
            $("#formLogin").remove();
            var regform = $("#formReg");
            var fields_container = $(".fields", regform);

            fields_container.empty();

            for (var f in AppData.registration.fields) {
                var field = AppData.registration.fields[f];

                var p = $("<p/>");
                var label = $("<label/>").addClass("inputgroup flex");
                var i = $("<i/>").addClass(field.icon);

                let input;

                if (field.userfield != "") {
                    input = $("<input/>").attr("name", field.userfield).attr("placeholder", field.label[localState.lang].text);
                    if (field.required) {
                        input.attr("required", "required");
                    }

                    if (field.userfield == "password") {
                        input.attr("type", "password");
                    }

                    if (field.userfield == "email") {
                        input.attr("type", "email");
                    }

                    if (field.required) {
                        input.attr("required", "required");
                    }
                } else {
                    switch (field.customfield) {
                        case "select":
                            input = $("<select/>").attr("name", field.fieldname);
                            let empty_option = $("<option/>").val("").text(field.label[localState.lang].text);

                            input.append(empty_option);

                            for (let i = 0; i < field.options_num; i++) {
                                let option = $("<option/>").val(field.options.value[i]).text(field.options[localState.lang].label[i]);
                                input.append(option);
                            }
                            if (field.required) {
                                input.attr("required", "required");
                            }

                            break;
                        case "checkbox":
                            let chk_label = $("<label/>").attr("for", field.fieldname).html(field.label[localState.lang].text);
                            let chk = $("<input/>").attr("type", "checkbox").attr("name", field.fieldname).attr("id", field.fieldname);
                            label.css({
                                "justify-content": "left"
                            });

                            i.addClass("hidden");

                            if (field.required) {
                                chk.attr("required", "required");
                            }

                            input = $("<div/>").addClass("reg_chk_cont flex");
                            input.append(chk);
                            input.append(chk_label);

                            break;
                        case "text":
                        default:
                            input = $("<input/>").attr("name", field.fieldname).attr("placeholder", field.label[localState.lang].text);
                            if (field.required) {
                                input.attr("required", "required");
                            }
                            break;
                    }
                }

                label.append(i);
                label.append(input);
                p.append(label);

                fields_container.append(p);
            }

            break;
    }
}
