var Modulsettings = (function () {
    'use strict';

    let regtimer;

    function Init() {
        $(".qrcode").toggle(AppData.modulsettings.showqrcode);

        if (AppData.modulsettings.floating_login_reg) {
            $("#landing nav").addClass("floating");

            let headerBg = hexToRgb("#FFFFFF");

            if ('floating_login_bg' in AppData.modulsettings) {
                headerBg = hexToRgb(AppData.modulsettings.floating_login_bg);
            }

            let headerOpacity = 1;
            if ('floating_login_opacity' in AppData.modulsettings) {
                headerOpacity = parseInt(AppData.modulsettings.floating_login_opacity) / 100;
            }
            $("#landing nav").css("background-color", 'rgba(' + headerBg.r + ',' + headerBg.g + ',' + headerBg.b + ', '+headerOpacity+')');
            $("#landing nav").css("color", AppData.modulsettings.floating_login_text);
        } else {
            $("#landing nav").removeClass("floating");
            $("#landing nav").css("background-color", "");
            $("#landing nav").css("color", "");
        }

        let $layouts = $('main');
        /* Set Title*/
        $layouts.find('.header-primary .data-title').text(AppData.modulsettings.title[localState.lang]);

        /* Set SubTitle */
        $layouts.find('.header-primary .data-subtitle').text(AppData.modulsettings.subTitle[localState.lang]);

        if (AppData.modulsettings.cdown_showonreg == false) {
            $("#landing .countdown_bc").hide();
        } else {
            $("#landing .countdown_bc").show();
        }

        showCountDown(AppData.modulsettings.cdown_showonreg, AppData.modulsettings.cdown_regpos);
    }

    function BuildFromSource() {
        $.post('api/', {
            do: 'getModulSettings'
        }, function (data) {
            AppData.modulsettings = data;
            Init();
            data = null;
        })
    }

    return {
        Init: Init,
        BuildFromSource: BuildFromSource
    }
})();
