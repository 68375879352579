/*
 Copyright (C) COMPOFFICE-R Kft, RentIT - All Rights Reserved
 Unauthorized copying of this file, via any medium is strictly prohibited
 Proprietary and confidential

 */
var Settings = (function () {
    'use strict';

    /* ----- Init Settings -------------------------------------------------------------------------------------------- */
    function Init() {
        var
            data = AppData.settings || [],
            inlineStyle = '',
            $layouts = $('main');
        /* Set Background Image */
        if ('bgImage' in data) {
            let bg = AppData.medias.find(function (item){
                return item.id == data.bgImage;
            });
            if (bg && bg.files[localState.lang] != undefined) {
                $layouts
                    .filter('#landing').find('header')
                    .css('backgroundImage', 'url("' + bg.files[localState.lang].file + '")');
            }
        }
        /* Set Foreground Image */
        $layouts.filter('#landing').find('.hero').hide();

        if (AppData.settings.foregroundtype == "image") {
            if ('fgImage' in data) {
                let fg = AppData.medias.find(function (item){
                    return item.id == data.fgImage;
                });
                if (fg && fg.files[localState.lang] != undefined) {
                    $layouts
                        .filter('#landing').find('.hero')
                        .html("")
                        .css('backgroundImage', 'url("' + fg.files[localState.lang].file + '")').show();
                }
            }
        }

        if (AppData.settings.foregroundtype == "text") {
            if (AppData.modulsettings.fgtext) {
                $layouts
                    .filter('#landing').find('.hero')
                    .css('backgroundImage', 'none')
                    .html(AppData.modulsettings.fgtext[localState.lang]).show();
            }
        }
        /* Set Logo Image */
        if ('logo' in data) {
            let logo = AppData.medias.find(function (item){
                return item.id == data.logo;
            });
            if (logo && logo.files[localState.lang] != undefined) {
                $layouts.filter('#landing').find('header .logo').attr('src', logo.files[localState.lang].file);
                $layouts.find('.header-primary .logo').attr('src', logo.files[localState.lang].file);
            }
        }
        /* Set Start Page */
        fadeInLanding('landing');
        /* Set Logo Alignment */
        if ('logoPosition' in data) {
            $layouts.filter('#landing').find('header .container').css('justify-content', data.logoPosition);
        }

        /* Set Title*/
        $layouts.find('.header-primary .data-title').text(AppData.modulsettings.title[localState.lang]);

        /* Set SubTitle */
        $layouts.find('.header-primary .data-subtitle').text(AppData.modulsettings.subTitle[localState.lang]);

        /* Set UserName */
        if ('user' in data) {
            $layouts.filter('#welcome').find('article .data-user').text(data.user);
            $layouts.find('.header-primary .data-user').text(data.user);
        }
        /* Set Software Version Visibility  */
        if ('showVersion' in data) {
            $('#software').toggle(String(data.showVersion) === 'true');
        }
        /* Set Text Direction */
        if ('textDirection' in data) {
            $(document.documentElement).attr('dir', data.textDirection);
        }
        /* Set Header Reverse */
        if ('headerReverse' in data) {
            $layouts.find('.header-primary').toggleClass('reverse', String(data.headerReverse) === 'true');
        }
        /* Set Change Language Visibility */
        if ('changeLang' in data) {
            $layouts.find('.changeLangHide').toggleClass('hidden', String(data.changeLang) === 'false');
        }
        /* Set Primary Color */
        if ('colorPrimary' in data) {
            inlineStyle += '.color-primary { color: ' + data.colorPrimary + ' !important; }';
            inlineStyle += '.bg-primary { background-color: ' + data.colorPrimary + ' !important; }';
            inlineStyle += '.border-primary { border-color: ' + data.colorPrimary + ' !important; }';
            /*$layouts.find( '.header-secondary' ).css( 'backgroundColor', data.colorPrimary );
             $layouts.find( 'nav .data-icon' ).css( 'color', data.colorPrimary );
             $layouts.find( '.scroll-top' ).css( 'backgroundColor', data.colorPrimary );*/
        }
        /* Set Secondary Color */
        if ('colorSecondary' in data) {
            inlineStyle += '.color-accent { color: ' + data.colorSecondary + ' !important; }';
            inlineStyle += '.bg-accent { background-color: ' + data.colorSecondary + ' !important; }';
            inlineStyle += '.border-accent { border-color: ' + data.colorSecondary + ' !important; }';
            /*$layouts.find( '.header-primary .data-title' ).css( 'color', data.colorSecondary );
             $layouts.find( '.header-primary .data-subtitle' ).css( 'color', data.colorSecondary );
             $layouts.find( 'nav .data-title' ).css( 'color', data.colorSecondary );
             $( document.body ).css( 'backgroundColor', data.colorSecondary );*/
        }
        /* Set Inline Styles */
        if (inlineStyle.length) {
            $('head').append('<style>' + inlineStyle + '</style>');
        }

        let headerBg = hexToRgb("#FFFFFF");

        if ('colorHeaderBackground' in data) {
            headerBg = hexToRgb(AppData.settings.colorHeaderBackground);
        }

        let headerOpacity = 1;
        if ('headerOpacity' in data) {
            headerOpacity = AppData.settings.headerOpacity / 100;
        }

        $("main > header > section").css("background-color", 'rgba(' + headerBg.r + ',' + headerBg.g + ',' + headerBg.b + ', '+headerOpacity+')');

        if (headerOpacity == 0) {
            $("main > header").css("background-position-y", $("main > header > section").height()+"px");
        } else {
            $("main > header").css("background-position-y", "");
        }

        if ('colorHeaderText' in data) {
            $("main > header > .header-primary").css("color", AppData.settings.colorHeaderText);
        }
    }

    /* ----- FadeIn Landing Layout ------------------------------------------------------------------------------------ */
    function fadeInLanding(layout) {
        var startLayout = layout || 'landing';
        requestAnimationFrame(function () {
            $('main#landing, main#game').hide();
            $('main#' + startLayout).addClass('animated fadeIn');
            $('main#' + startLayout).show();
        });
    }

    function BuildFromSource() {
        $.post('api/', {
            do: 'getSettings'
        }, function (data) {
            AppData.settings = data;
            setFavicon();
            Init();
            data = null;
        })
    }

    /* ----- Public API ----------------------------------------------------------------------------------------------- */
    return {
        Init: Init,
        BuildFromSource: BuildFromSource
    };
})();
